<template>
  
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/>
        <sidebar-link to="/geo" :name="$t('Dados geoespaciais')" icon="tim-icons icon-atom"/>
        <sidebar-link to="/maps" :name="$t('Mapas')" icon="tim-icons icon-pin"/>
        <sidebar-link to="/notifications" :name="$t('Notificações e avisos')" icon="tim-icons icon-bell-55"/>
        <sidebar-link to="/profile" :name="$t('Perfil')" icon="tim-icons icon-single-02"/>
        <sidebar-link to="/regioes" :name="$t('Regiões')" icon="tim-icons icon-puzzle-10"/>
        <sidebar-link to="/suporte" :name="$t('Suporte')" icon="tim-icons icon-align-center"/>
        
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
