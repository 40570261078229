import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import SamplePage from "@/layout/starter/SamplePage.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");

const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Leaflet_Map.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");

// Indices
const FAI = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/FAI.vue");
const CDOM = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/CDOM.vue");
const ChlorophyllA = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/ChlorophyllA.vue");
const CIRE = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/CIRE.vue");
const DO = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/DO.vue");
const DOBE = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/DO BE.vue");
const EVI = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/EVI.vue");
const LAI = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/LAI.vue");
const NDMI = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/NDMI.vue");
const NDVI = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/NDVI.vue");
const NDWI = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/NDWI.vue");
const Secchi = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/Secchi.vue");
const TEMP = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/TEMP.vue");
const TSI_class = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/TSI_class.vue");
const TSI = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/TSI.vue");
const TSM = () => import(/* webpackChunkName: "common" */ "@/pages/Indices/TSM.vue");

// Login

const Login = () => import(/* webpackChunkName: "common" */ "@/components/Login.vue");
const Register = () => import(/* webpackChunkName: "common" */ "@/components/Register.vue");

const routes = [
  {path:"/login", component: Login},
  {path:"/register", component: Register},
  {
    path: "/",
    component: DashboardLayout,
    redirect: "dashboard",
    children: [
      
      {
      path: "dashboard",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "profile",
      name: "profile",
      component: Profile
    },
    {
      path: "notifications",
      name: "notifications",
      component: Notifications
    },
    {
      path: "geo",
      name: "geo",
      component: Icons
    },
    {
      path: "maps",
      name: "maps",
      component: Maps
    },
    {
      path: "suporte",
      name: "support",
      component: Typography
    },
    {
      path: "regioes",
      name: "Regiôes",
      component: TableList
    },
    {
      path: "fai",
      name: "fai",
      component: FAI
    },
    {
      path: "cdom",
      name: "cdom",
      component: CDOM
    },
    {
      path: "chlorophylla",
      name: "chlorophylla",
      component: ChlorophyllA
    },
    {
      path: "cire",
      name: "cire",
      component: CIRE
    },
    {
      path: "do",
      name: "do",
      component: DO
    },
    {
      path: "do_be",
      name: "do_be",
      component: DOBE
    },
    {
      path: "evi",
      name: "evi",
      component: EVI
    },
    {
      path: "lai",
      name: "lai",
      component: LAI
    },
    {
      path: "ndmi",
      name: "ndmi",
      component: NDMI
    },
    {
      path: "ndvi",
      name: "ndvi",
      component: NDVI
    },
    {
      path: "ndwi",
      name: "ndwi",
      component: NDWI
    },
    {
      path: "secchi",
      name: "secchi",
      component: Secchi
    },
    {
      path: "temp",
      name: "temp",
      component: TEMP
    },
    {
      path: "tsi_class",
      name: "tsi_class",
      component: TSI_class
    },
    {
      path: "tsi",
      name: "tsi",
      component: TSI
    },
    {
      path: "tsm",
      name: "tsm",
      component: TSM
    }

  ]},
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
