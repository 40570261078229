<template>
  <div class="row"><h3>Starter page</h3></div>
</template>
<script>
export default {
  name: 'starter-page',
  data() {
    return {};
  }
};
</script>
<style></style>
